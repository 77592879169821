/* You can add global styles to this file, and also import other style files */
@use '@tecalisdev/infinity';
@use '@tecalisdev/infinity/variables' as variables;

$font-family: 'proxima-nova', sans-serif;
$b-14: 400 0.875rem / 1.125rem #{$font-family};
$b-12: 400 0.75rem / 1rem #{$font-family};

//Border Radius
$br-2xs: 2px;
$br-xs: 4px;
$br-sm: 8px;
$br-md: 16px;
$br-lg: 116px;

//Box shadow
$bs-soft: 0px 12px 24px 0px rgba(18, 15, 24, 0.05);

//Sizes
$size-4xs: 0.125rem; //2
$size-3xs: 0.25rem; //4
$size-2xs: 0.5rem; //8
$size-xs: 0.75rem; //12
$size-sm: 1rem; //16
$size-md: 1.5rem; //24
$size-lg: 2rem; //32
$size-xl: 2.5rem; //40
$size-2xl: 3rem; //48
$size-3xl: 4rem; //64
$size-4xl: 5rem; //80
$size-5xl: 7.25rem; //116

@mixin flex ($fd: null, $fw: null, $ff: null, $jc: null, $ai: null, $ac: null) {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;

    flex-direction: $fd;
    -webkit-flex-direction: $fd;

    flex-wrap: $fw;
    -ms-flex-wrap: $fw;
    -webkit-flex-wrap: $fw;

    flex-flow: $ff;
    -ms-flex-flow: $ff;
    -webkit-flex-flow: $ff;

    justify-content: $jc;
    -ms-justify-content: $jc;
    -webkit-justify-content: $jc;

    align-items: $ai;
    -ms-align-items: $ai;
    -webkit-align-items: $ai;

    align-content: $ac;
    -ms-align-content: $ac;
    -webkit-align-content: $ac;
}

@mixin flex-child ($o: null, $fg: null, $fs: null, $fb: null, $as: null) {
    order: $o;
    -ms-flex-order: $o;
    -webkit-order: $o;

    flex-grow: $fg;
    -ms-flex-grow: $fg;
    -webkit-flex-grow: $fg;

    flex-shrink: $fs;
    -ms-flex-shrink: $fs;
    -webkit-flex-shrink: $fs;

    flex-basis: $fb;
    -ms-flex-basis: $fb;
    -webkit-flex-basis: $fb;

    align-self: $as;
    -ms-align-self: $as;
    -webkit-align-self: $as;
}

@mixin position ($p: null, $t: null, $r: null, $b: null, $l: null) {
    position: $p;
    top: $t;
    right: $r;
    bottom: $b;
    left: $l;
}

@function rem($value) {
    $remValue: calc($value / 16) + rem;
    @return $remValue;
    //@return $value + px;
}

:root #pensumo {
    --color-primary-purple-01: #24C593;
    --color-primary-purple-02: #6DE4C0;
    --color-primary-purple-03: #1D9B74;
    --color-primary-carnation-01: #24C593;
    --color-primary-carnation-02: #FEDC85;
    --color-primary-carnation-03: #EAA701;
    --color-primary-cherry-pie-01: #080015;
    --color-primary-cherry-pie-01-button: #24C593;
    --color-primary-cherry-pie-02: #68E4E2;
    --color-primary-cherry-pie-03: #1B9796;
    --color-primary-java-01: #1D9B74;
    --color-primary-java-02: #DDFFFE;
    --color-primary-java-03: #24C593;
    --color-accent-green-01: #0E4D39;
    --color-accent-green-02: #43CCA2;
    --color-accent-orange-01: #EE1D23;
    --color-accent-yellow-01: #FEBE1F;
    --color-feedback-blue: #DDFFFE;

    --color-cherry-pie-01-opacity: rgba(14, 24, 62, 0.25);
}

html {
    body {
        &#pensumo {
            small {
                font: $b-14;

            }

            //HELPERS
            .gap-32 {
                @include flex($fd: column);
                gap: $size-lg;
            }

            //PROTECCION DE DATOS INFO
            .pdd-info {
                @include flex($fd: column);
                gap: $size-3xs;
                color: var(--color-neutral-06);
                font: $b-14;

                span {
                    text-transform: uppercase;
                }

                a {
                    font: $b-14;
                }
            }

            ul {
                color: var(--color-neutral-07);
                padding: 0 0 0 $size-md;
            }

            .auth {
                &__header {
                    &__text {
                        p {
                            .text-gray {
                                color: var(--color-neutral-05);
                            }
                        }
                    }
                }

                .container {
                    .auth {
                        &__body {
                            &__image-pre-login {
                                margin-bottom: rem(56);
                            }
                        }
                    }
                }
            }

            .no-sidenav {
                .content {
                    &__container {
                        background-color: var(--color-neutral-02);
                        // .template-create{
                        //     &__contents{
                        //         background-color: var(--color-neutral-02);
                        //     }
                        // }
                    }
                }
            }

            //TOPNAV
            .topnav {
                &__container {
                    &__actions {
                        .link {
                            color: var(--color-primary-purple-01);
                        }
                    }
                }
            }

            //ITEM
            .item {
                &--highlight {
                    background-color: var(--color-primary-cherry-pie-01-button);
                }
            }

            //FORM-GROUP
            .form-group {
                &--image {
                    gap: $size-sm;
                    @include flex($ai: flex-start);
                }

                &__image {
                    height: $size-lg;
                }
            }

            //TAG-IMAGE
            .tag-image {
                &--control {
                    background: var(--color-primary-java-02);
                    color: var(--color-primary-java-03);
                }
            }

            //BUTTON-RED
            .button--red {
                background-color: #F75F00;
                border-color: #F75F00;
            }

            //TAG-ICON
            .tag-icon {
                i.icon-alert {
                    color: var(--color-neutral-01);
                }
            }

            //MENU
            .menu {
                &__menu {
                    &__item--red {
                        color: #F75F00;
                    }
                }
            }
        }
    }
}